import React from 'react';
import Layout from '../../components/Shared/Layout';
import BrilliantlyWarm from '../../components/Portfolio/brilliantly-warm';
import '../../assets/css/sesame.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class BrilliantlyWarmPage extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Portfolio/raven',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <BrilliantlyWarm />
      </Layout>
    );
  }
}

export default BrilliantlyWarmPage;
